import React, { useEffect } from 'react';
import './Projects.css';
import projectImg1 from './images/cwm.jpg';
import projectImg2 from './images/jellyfish.png';
import Car1 from '../Cars/Car1';
import Car2 from '../Cars/Car2';
import DescP1 from './Desc/DescP1';
import DescP2 from './Desc/DescP2';
import Card from './OtherProjects/Card';

// Other projects' data and img
import projectsData from './OtherProjects/projectsData';

const Projects = ({
  route,
  car1Clicked,
  setCar1Clicked,
  car2Clicked,
  setCar2Clicked,
}) => {
  useEffect(() => {
    const projects = document.querySelector('.Projects');
    const otherProjects = document.querySelector('.other-projects');

    route === 'Projects'
      ? projects.classList.add('show-projects')
      : projects.classList.remove('show-projects');

    route === 'Projects'
      ? otherProjects.classList.add('show-other-projects')
      : otherProjects.classList.remove('show-other-projects');
  }, [route]);

  useEffect(() => {
    const pickupProjects = document.querySelector('.pickup-projects');
    car1Clicked
      ? pickupProjects.classList.add('hide-pickup-projects-p1')
      : pickupProjects.classList.remove('hide-pickup-projects-p1');

    car2Clicked
      ? pickupProjects.classList.add('hide-pickup-projects-p2')
      : pickupProjects.classList.remove('hide-pickup-projects-p2');
  }, [car1Clicked, car2Clicked]);

  useEffect(() => {
    const projects = document.querySelector('.Projects');
    if (car1Clicked || car2Clicked) projects.scrollTo(0, 0);
  }, [car1Clicked, car2Clicked]);

  return (
    <div className="Projects">
      <div className="diagonal-line"></div>
      <div className="diagonal-line-bottom"></div>
      <div className="pickup-projects">
        <h2>PICKUP</h2>
      </div>

      <div className="featured-projects-container">
        <Car1
          projectImg1={projectImg1}
          car1Clicked={car1Clicked}
          setCar1Clicked={setCar1Clicked}
          car2Clicked={car2Clicked}
        />
        <Car2
          projectImg2={projectImg2}
          car2Clicked={car2Clicked}
          setCar2Clicked={setCar2Clicked}
          car1Clicked={car1Clicked}
        />
        <DescP1
          car1Clicked={car1Clicked}
          setCar1Clicked={setCar1Clicked}
          route={route}
        />
        <DescP2
          car2Clicked={car2Clicked}
          setCar2Clicked={setCar2Clicked}
          route={route}
        />
      </div>

      <div className="other-projects">
        <div className="more-projects">
          <h2>MORE</h2>
        </div>
        {projectsData.map(projectData => (
          <Card key={projectData.id} projectData={projectData} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
