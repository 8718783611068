import React, { useState, useEffect, useRef } from 'react';
import './MenuBtn.css';
import useStore from "../../store";

const MenuBtn = ({ route }) => {
  const [toggleClicked, setToggleClicked] = useState(false);
  const scrollTop = useStore(s => s.scrollTop);
  const showNav = useStore(s => s.showNav);
  const setShowNav = useStore(s => s.setShowNav);
  const menuBtnRef = useRef(null);
  const menuBtnBubbleRef = useRef(null);

  // Fix the position using 'scrollTop' value ('position:fixed' doesn't work on mobile)
  useEffect(() => {
    if (menuBtnRef) menuBtnRef.current.style.top = `${scrollTop + 30}px`;
  }, [scrollTop]);

  // Change colors on navigate
  useEffect(() => {
    const scrollPercent =
      route === 'About'
        ? 0
        : route === 'Projects'
          ? 1
          : route === 'Contact'
            ? 2
            : 0;

    menuBtnBubbleRef && menuBtnBubbleRef.current.scrollTo(0, scrollPercent * 100);
  }, [route]);

  // Wobble on click
  useEffect(() => {
    if (toggleClicked) {
      menuBtnRef && menuBtnRef.current.classList.add('add-wobble');
      setTimeout(() => {
        menuBtnRef && menuBtnRef.current.classList.remove('add-wobble');
        setToggleClicked(false);
      }, 1500);
    }
  }, [toggleClicked]);

  // Move on click
  useEffect(() => {
    menuBtnRef && showNav
      ? menuBtnRef.current.classList.add('move-toggle-bubble')
      : menuBtnRef.current.classList.remove('move-toggle-bubble');
  }, [showNav]);

  // Handle click
  const handleToggleClick = () => {
    setShowNav(!showNav);
    setToggleClicked(true);
  };

  return (
    <div ref={menuBtnRef} className="MenuBtn" onClick={handleToggleClick}>
      <div ref={menuBtnBubbleRef} className="menu-btn-bubble">
        <div className="menu-btn-about"></div>
        <div className="menu-btn-project"></div>
        <div className="menu-btn-contact"></div>
      </div>
      {showNav ? (
        <i className="fas fa-star toggle-icon"></i>
      ) : (
        <i className="far fa-star toggle-icon"></i>
      )}
    </div>
  );
};

export default MenuBtn;
