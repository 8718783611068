import React, { useEffect } from 'react';
import useStore from "../../store";

const Car2 = ({
  projectImg2,
  car2Clicked,
  setCar2Clicked,
  car1Clicked,
}) => {
  const setShowNav = useStore(s => s.setShowNav);
  useEffect(() => {
    const cog1 = document.querySelector('.Car2 .cog1');
    const cog2 = document.querySelector('.Car2 .cog2');
    const carTire1 = document.querySelector('.Car2 .car-tire1');
    const carTire2 = document.querySelector('.Car2 .car-tire2');
    const carBody = document.querySelector('.Car2 .car-body');
    const imgContainer = document.querySelector('.Car2 .img-container');

    if (car2Clicked) {
      cog1.classList.add('play');
      cog2.classList.add('play');
      carTire1.classList.add('play');
      carTire2.classList.add('play');
      carBody.classList.add('play');
      imgContainer.classList.add('play');
      setShowNav(false);
    } else {
      cog1.classList.remove('play');
      cog2.classList.remove('play');
      carTire1.classList.remove('play');
      carTire2.classList.remove('play');
      carBody.classList.remove('play');
      imgContainer.classList.remove('play');
    }
  }, [car2Clicked, setShowNav]);

  useEffect(() => {
    const car2 = document.querySelector('.Car2');
    car1Clicked
      ? car2.classList.add('hide-car2')
      : car2.classList.remove('hide-car2');
  }, [car1Clicked]);

  return (
    <div className="Car2" onClick={() => setCar2Clicked(true)}>
      <div className="scale-container">
        <div className="car-body driving-body"></div>
        <i className="fas fa-cog cog1 driving-cog1"></i>
        <div className="img-container driving-img">
          <img src={projectImg2} alt="" width="230px" />
        </div>
        <i className="fas fa-cog cog2 driving-cog2"></i>
        <div className="title-container">
          <h3>Hut Kutter</h3>
        </div>
        <div className="car-tire1 driving-tire1"></div>
        <div className="car-tire2 driving-tire2"></div>
      </div>
    </div>
  );
};

export default Car2;
