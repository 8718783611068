import React, { useEffect } from 'react';
import './DescP2.css';
import './Desc.css';

const DescP2 = ({ car2Clicked, setCar2Clicked, route }) => {
  useEffect(() => {
    const descP2 = document.querySelector('.DescP2');
    car2Clicked
      ? descP2.classList.add('show-Desc')
      : descP2.classList.remove('show-Desc');
  }, [car2Clicked]);

  useEffect(() => {
    const descP2 = document.querySelector('.DescP2');
    if (route !== 'Projects') {
      descP2.classList.remove('show-Desc');
      setCar2Clicked(false);
    }
  }, [route, setCar2Clicked]);

  return (
    <div className="DescP2 Desc">
      <i
        className="fas fa-times-circle exit-btn"
        onClick={() => setCar2Clicked(false)}
      ></i>
      <h2>Hut Kutter</h2>
      <p>
        A development group of two developers.
        <br />
        Launched two android apps
        that have 100+ and 500+ downloads respectively.
        <br />
        Built a website which leverages image styling AI.
      </p>

      <h2>Skills</h2>
      <ul>
        <li>Game</li>
        <li>Android</li>
        <li>Web App</li>
      </ul>

      <div className="link">
        {/* <a
          href="https://github.com/Kota-N/potatofy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Code
        </a> */}
        <a
          href="https://play.google.com/store/apps/developer?id=Hut+Kutter"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play
        </a>
      </div>
    </div>
  );
};

export default DescP2;
