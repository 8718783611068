import React, { useEffect } from 'react';
import './About.css';
import useStore from "../../store";

const About = ({ route, setRoute }) => {
  const setShowNav = useStore(s => s.setShowNav);
  useEffect(() => {
    const about = document.querySelector('.About');
    const extraInfo = document.querySelector('.extra-info');

    route === 'About'
      ? about.classList.add('show-about')
      : about.classList.remove('show-about');
    route === 'About'
      ? extraInfo.classList.add('show-extra-info')
      : extraInfo.classList.remove('show-extra-info');
  }, [route]);
  return (
    <div className="About">
      <div className="diagonal-line"></div>
      <div className="about-container">
        <h3>Welcome!</h3>
        <p>
          <span
            className="many-more"
            onClick={() => {
              setRoute('Projects');
              setShowNav(false);
            }}
          >
            PROJECTS
          </span><br />
          <span
            className="many-more"
            onClick={() => {
              setRoute('Contact');
              setShowNav(false);
            }}
          >
            CONTACT
          </span>
        </p>
      </div>
      <div className="diagonal-line-bottom"></div>
      <div className="extra-info">
        <h3>CONTACT</h3>
        <h4>Email</h4>
        <p>kotancell@gmail.com</p>
        <h4>GitHub</h4>
        <p>@Kota-N</p>
      </div>

      <div className="skill-bubbles skill1">
        <h3>React</h3>
      </div>
      <div className="skill-bubbles skill2">
        <h3>TypeScript</h3>
      </div>
      <div className="skill-bubbles skill3">
        <h3>AWS</h3>
      </div>
      <div className="skill-bubbles skill4">
        <h3>SQL</h3>
      </div>
    </div>
  );
};

export default About;
