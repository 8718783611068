import React, { useEffect } from 'react';
import './Cars.css';
import './CarAnimation.css';
import useStore from "../../store";

const Car1 = ({
  projectImg1,
  car1Clicked,
  setCar1Clicked,
  car2Clicked,
}) => {
  const setShowNav = useStore(s => s.setShowNav);
  useEffect(() => {
    const cog1 = document.querySelector('.Car1 .cog1');
    const cog2 = document.querySelector('.Car1 .cog2');
    const carTire1 = document.querySelector('.Car1 .car-tire1');
    const carTire2 = document.querySelector('.Car1 .car-tire2');
    const carBody = document.querySelector('.Car1 .car-body');
    const imgContainer = document.querySelector('.Car1 .img-container');

    if (car1Clicked) {
      cog1.classList.add('play');
      cog2.classList.add('play');
      carTire1.classList.add('play');
      carTire2.classList.add('play');
      carBody.classList.add('play');
      imgContainer.classList.add('play');
      setShowNav(false);
    } else {
      cog1.classList.remove('play');
      cog2.classList.remove('play');
      carTire1.classList.remove('play');
      carTire2.classList.remove('play');
      carBody.classList.remove('play');
      imgContainer.classList.remove('play');
    }
  }, [car1Clicked, setShowNav]);

  useEffect(() => {
    const car1 = document.querySelector('.Car1');
    car2Clicked
      ? car1.classList.add('hide-car1')
      : car1.classList.remove('hide-car1');
  }, [car2Clicked]);

  return (
    <div className="Car1" onClick={() => setCar1Clicked(true)}>
      <div className="scale-container">
        <div className="car-body driving-body"></div>
        <i className="fas fa-cog cog1 driving-cog1"></i>
        <div className="img-container driving-img">
          <img src={projectImg1} alt="" width="230px" />
        </div>

        <i className="fas fa-cog cog2 driving-cog2"></i>
        <div className="title-container">
          <h3>Stock Music Website</h3>
        </div>
        <div className="car-tire1 driving-tire1"></div>
        <div className="car-tire2 driving-tire2"></div>
      </div>
    </div>
  );
};

export default Car1;
