import React, { useEffect } from 'react';
import './Nav.css';
import useStore from "../../store";

const Nav = ({ setRoute }) => {
  // Fix the position using 'scrollTop' value ('position:fixed' doesn't work on mobile)
  const scrollTop = useStore(s => s.scrollTop);
  const showNav = useStore(s => s.showNav);
  const setShowNav = useStore(s => s.setShowNav);

  useEffect(() => {
    const nav = document.querySelector('nav');
    nav.style.top = `${scrollTop}px`;
  }, [scrollTop]);

  useEffect(() => {
    const nav = document.querySelector('nav');
    showNav ? nav.classList.add('show-nav') : nav.classList.remove('show-nav');
  }, [showNav]);
  return (
    <nav>
      <ul>
        <li
          id="about"
          onClick={() => {
            setRoute('About');
            setShowNav(false);
          }}
        >
          <i className="fas fa-long-arrow-alt-right nav-arrow"></i> ABOUT
          <div className="underline underline-about"></div>
        </li>
        <li
          id="projects"
          onClick={() => {
            setRoute('Projects');
            setShowNav(false);
          }}
        >
          <i className="fas fa-long-arrow-alt-right nav-arrow"></i> PROJECTS
          <div className="underline underline-project"></div>
        </li>
        <li
          id="contact"
          onClick={() => {
            setRoute('Contact');
            setShowNav(false);
          }}
        >
          <i className="fas fa-long-arrow-alt-right nav-arrow"></i> CONTACT
          <div className="underline underline-contact"></div>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
