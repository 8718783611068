import React, { useEffect } from 'react';
import './DescP1.css';

const DescP1 = ({ car1Clicked, setCar1Clicked, route }) => {
  useEffect(() => {
    const descP1 = document.querySelector('.DescP1');
    car1Clicked
      ? descP1.classList.add('show-Desc')
      : descP1.classList.remove('show-Desc');
  }, [car1Clicked]);

  useEffect(() => {
    const descP1 = document.querySelector('.DescP1');
    if (route !== 'Projects') {
      descP1.classList.remove('show-Desc');
      setCar1Clicked(false);
    }
  }, [route, setCar1Clicked]);

  return (
    <div className="DescP1 Desc">
      <i
        className="fas fa-times-circle exit-btn"
        onClick={() => setCar1Clicked(false)}
      ></i>
      <h2>CodaWaterMusic</h2>
      <p>
        A web app (PWA) created with React.js.
        <br />A fully responsive web app
        with a collection of downloadable music.
      </p>

      <h2>Skills</h2>
      <ul>
        <li>React.js</li>
        <li>TypeScript</li>
        <li>React Router</li>
        <li>State Management (Zustand)</li>
        <li>Web Audio API</li>
      </ul>
      <div className="link">
        <a
          href="https://github.com/Kota-N/codawatermusic-v2"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        <a
          href="https://www.codawatermusic.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Live
        </a>
      </div>
    </div>
  );
};

export default DescP1;
