import React, { useEffect } from 'react';
import './Contact.css';

const Contact = ({ route }) => {
  useEffect(() => {
    const contact = document.querySelector('.Contact');
    route === 'Contact'
      ? contact.classList.add('show-contact')
      : contact.classList.remove('show-contact');
  }, [route]);
  return (
    <div className="Contact">
      <div className="diagonal-line"></div>
      <div className="contact-container">
        <h4>Email</h4>
        <p>kotancell@gmail.com</p>
        <h4>Github</h4>
        <p>@Kota-N</p>
      </div>
      <div className="diagonal-line-bottom"></div>
    </div>
  );
};

export default Contact;
