import React, { useEffect, useRef, useState } from 'react';
import './Card.css';

const Card = ({ projectData }) => {
  const [color] = useState(
    `rgba(146, 194, ${140 + Math.floor(Math.random() * 40)}, 0.8)`
  );
  const [isMouseEntered, setIsMouseEntered] = useState(false);

  const descContainerRef = useRef(null);

  useEffect(() => {
    if (isMouseEntered) {
      setTimeout(() => {
        descContainerRef.current.style = { display: 'flex' };
      }, 900);
    }
  }, [isMouseEntered]);

  return (
    <div
      className="Card"
      onMouseEnter={() => setIsMouseEntered(true)}
      style={
        isMouseEntered
          ? {
            maxWidth: '90vw',
            minWidth: '90vw',
            backgroundColor: color,
            justifyContent: 'flex-start',
          }
          : {
            maxWidth: '40vw',
            minWidth: '40vw',
            backgroundColor: '#f4f4f4',
            justifyContent: 'center',
          }
      }
    >
      <div className="leftside-container">
        <div className="image-container">
          <img src={projectData.image} alt="" />
        </div>
        <div className="link" onClick={e => e.stopPropagation()}>
          {projectData.codeUrl && (
            <a
              href={projectData.codeUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={isMouseEntered ? {} : { backgroundColor: 'darkgray' }}
            >
              GitHub
            </a>
          )}
          {projectData.visitUrl && (
            <a
              href={projectData.visitUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={isMouseEntered ? {} : { backgroundColor: 'gray' }}
            >
              Live
            </a>
          )}
        </div>
      </div>
      <div
        className="desc-container"
        ref={descContainerRef}
        style={{ display: 'none' }}
      >
        <div className="card-title">
          <h3>{projectData.title}</h3>
        </div>
        <p>{projectData.desc}</p>
        <ul>
          {projectData.skills.map((skill, i) => (
            <li key={skill[i]}>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Card;
