import React, { useState, useEffect, useRef } from 'react';
import Nav from './components/Nav/Nav';
import MenuBtn from './components/MenuBtn/MenuBtn';
import Circle from './components/Circle/Circle';
import Bubbles from './components/Bubbles/Bubbles';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import './App.css';
import useStore from "./store";

function App() {
  const [route, setRoute] = useState('About');
  const [car1Clicked, setCar1Clicked] = useState(false);
  const [car2Clicked, setCar2Clicked] = useState(false);
  const scrollElRef = useRef(null);

  const setScrollTop = useStore(s => s.setScrollTop);

  useEffect(() => {
    scrollElRef && scrollElRef.current.scrollTo(0, 0);
  }, [route]);

  useEffect(() => {
    if (car1Clicked || car2Clicked) scrollElRef && scrollElRef.current.scrollTo(0, 770);
  }, [car1Clicked, car2Clicked]);

  return (
    <div className="App">
      <div
        ref={scrollElRef}
        className="scroll-el"
        onScroll={e => setScrollTop(e.target.scrollTop)}
      >
        <Nav
          setRoute={setRoute}
        />
        <MenuBtn
          route={route}
        />
        <Circle route={route} />
        <Bubbles route={route} />
        <Contact route={route} />
        <Projects
          route={route}
          car1Clicked={car1Clicked}
          setCar1Clicked={setCar1Clicked}
          car2Clicked={car2Clicked}
          setCar2Clicked={setCar2Clicked}
        />
        <About route={route} setRoute={setRoute} />
      </div>
    </div>
  );
}

export default App;
