import React, { useEffect } from 'react';
import './Circle.css';

const Circle = ({ route }) => {
  useEffect(() => {
    // animate about-page title
    const myName = document.querySelector('.my-name');
    const nameLetters = myName.textContent.split('');
    myName.textContent = '';
    const developer = document.querySelector('.developer');
    setTimeout(() => {
      developer.classList.add('show-developer');
    }, 1200);

    for (let i = 0; i < nameLetters.length; i++) {
      if (i === 0) {
        myName.innerHTML += `<span class="name-animation"><span class="k">${nameLetters[i]}</span></span>`;
      } else if (i === 5) {
        myName.innerHTML += `<span class="name-animation"><span class="n">${nameLetters[i]}</span></span>`;
      } else {
        myName.innerHTML += `<span class="name-animation">${nameLetters[i]}</span>`;
      }
    }

    let letterNum = 0;

    const animateTitle = () => {
      const nameSpan = document.querySelectorAll('.name-animation')[letterNum];
      nameSpan.classList.add('fade-in');
      letterNum++;

      if (letterNum === nameLetters.length) {
        finishAnimation();
        return;
      }
    };
    const finishAnimation = () => {
      clearInterval(timer);
      timer = null;
    };

    let timer = setInterval(animateTitle, 50);
  }, []);

  useEffect(() => {
    // Scroll on route change
    const circle = document.querySelector('.Circle');
    if (route === 'About') {
      circle.scrollTo(0, 0);
    } else if (route === 'Projects') {
      circle.scrollTo(0, 640);
    } else if (route === 'Contact') {
      circle.scrollTo(0, 1280);
    }
  }, [route]);

  return (
    <div className="Circle">
      <div className="about-page">
        <h1 className="my-name">Kota Nishigaki</h1>
        <h2 className="developer">Developer</h2>
      </div>

      <div className="project-page">
        <h2>PROJECTS</h2>
      </div>

      <div className="contact-page">
        <h2>CONTACT</h2>
      </div>
    </div>
  );
};

export default Circle;
