import React, { useEffect } from 'react';
import './Bubbles.css';

const Bubbles = ({ route }) => {
  useEffect(() => {
    // bubbles
    const bubbles = document.querySelectorAll('.bubble');
    const bubble1 = document.querySelector('.bubble1');
    const bubble2 = document.querySelector('.bubble2');
    const bubble3 = document.querySelector('.bubble3');
    const bubble5 = document.querySelector('.bubble5');

    // disperse bubbles
    const disperseBubbles = bubble => {
      setTimeout(() => bubble.classList.remove('hide-bubble'), 1000);
    };
    for (let i = 0; i < bubbles.length; i++) {
      disperseBubbles(bubbles[i]);
    }

    // wobble the bubbles at different timing
    const addWobble = (bubble, time) => {
      setInterval(() => {
        bubble.classList.add('add-wobble');
        setTimeout(() => {
          bubble.classList.remove('add-wobble');
        }, 2100);
      }, time);
    };
    addWobble(bubble1, 12000);
    addWobble(bubble2, 17000);
    addWobble(bubble3, 23000);
    addWobble(bubble5, 27000);
  }, []);

  useEffect(() => {
    const bubble1 = document.querySelector('.bubble1');
    const bubble2 = document.querySelector('.bubble2');
    const bubble3 = document.querySelector('.bubble3');
    const bubble5 = document.querySelector('.bubble5');
    // change little bubbles color
    const scrollPercent =
      route === 'About'
        ? 0
        : route === 'Projects'
        ? 1
        : route === 'Contact'
        ? 2
        : 0;
    bubble1.scrollTo(0, scrollPercent * 300);
    bubble2.scrollTo(0, scrollPercent * 200);
    bubble3.scrollTo(0, scrollPercent * 150);
    bubble5.scrollTo(0, scrollPercent * 150);
  }, [route]);

  return (
    <div className="Bubbles">
      <div className="bubble hide-bubble container-bubble1">
        <div className="bubble1">
          <div className="about-bubble1"></div>
          <div className="project-bubble1"></div>
          <div className="contact-bubble1"></div>
        </div>
      </div>

      <div className="bubble hide-bubble container-bubble2">
        <div className="bubble2">
          <div className="about-bubble2"></div>
          <div className="project-bubble2"></div>
          <div className="contact-bubble2"></div>
        </div>
      </div>

      <div className="bubble hide-bubble container-bubble3">
        <div className="bubble3">
          <div className="about-bubble3"></div>
          <div className="project-bubble3"></div>
          <div className="contact-bubble3"></div>
        </div>
      </div>

      <div className="bubble hide-bubble container-bubble5">
        <div className="bubble5">
          <div className="about-bubble5"></div>
          <div className="project-bubble5"></div>
          <div className="contact-bubble5"></div>
        </div>
      </div>
    </div>
  );
};

export default Bubbles;
