import guitarshopImage from '../images/guitarshop.jpg';
import wallpaperImage from '../images/wallpaper.jpg';
import phishtrackImage from '../images/phishtrack.jpg';
import vaccineImage from '../images/vaccine.png';
import caterpillarImage from '../images/caterpillar.png';
import ebayImage from '../images/ebay.jpg';
import zillowImage from '../images/zillow.jpg';
import toypianoImage from '../images/toypiano.jpg';
import validatorImage from '../images/validator.jpg';

const projectsData = [
  {
    id: '1',
    title: 'Guitar Shop Design',
    image: guitarshopImage,
    codeUrl: 'https://github.com/Kota-N/guitarshop-design',
    visitUrl: 'https://kota-n.github.io/guitarshop-design/',
    desc:
      'A design for a guitar shop landing page. Features page design, CSS animation, and form validation.',
    skills: ['Vanila JavaScript', 'CSS Animation', 'Web Design'],
  },
  {
    id: '2',
    title: 'Wallpaper AI',
    image: wallpaperImage,
    codeUrl: '',
    visitUrl: 'https://wallpaper-ai.web.app/',
    desc:
      'A react app with a styling AI. Its serverless backend is written in Node.js. Pick an image and edit it. After submitting, it sends back a downloadable image styled with the AI.',
    skills: [
      'React.js',
      'React-router',
      'Redux',
      'Redux-thunk',
      'Node.js',
      'Web Design',
      'Firebase Functions',
      'Deep AI',
    ],
  },
  {
    id: '3',
    title: 'Phishtrack',
    image: phishtrackImage,
    codeUrl: 'https://github.com/Kota-N/PhishTrack.git',
    visitUrl: '',
    desc:
      'A desktop app built personally for a worker in a security department at Wake Forest Baptist Medical Center. It tracks compromised accounts and exports a needed portion of the data in a CSV format.',
    skills: ['C++', 'Qt5', 'QML', 'SQLite'],
  },
  {
    id: '4',
    title: 'Zombie Vaccine',
    image: vaccineImage,
    codeUrl: 'https://github.com/Kota-N/zombie-vaccine-mobile.git',
    visitUrl: 'https://stupefied-bhaskara-b09d5c.netlify.app/',
    desc:
      'A clicker game built with React.js (and React Native for mobile). Click the vaccine button to create vaccine, sell the vaccines, hire workers, and save the people!',
    skills: ['React.js', 'React Native', 'Game Dev'],
  },
  {
    id: '5',
    title: 'Caterpillar Jump',
    image: caterpillarImage,
    codeUrl: 'https://github.com/Kota-N/CaterpillarJump.git',
    visitUrl:
      'https://play.google.com/store/apps/details?id=com.kn.caterpillarjump&hl=en_US&gl=US',
    desc:
      "A simple game app personally developed for my son. Jump and avoid flying mushrooms! It's available at Google Play Store.",
    skills: ['Game Dev', 'Godot (Game Enigine)', 'GDScript'],
  },
  {
    id: '6',
    title: 'eBay Price Tracker',
    image: ebayImage,
    codeUrl: 'https://github.com/Kota-N/ebay-price-tracker-flask.git',
    visitUrl: 'https://kota-n.github.io/ebay-price-tracker-mockup/',
    desc:
      'A program that tracks prices of ebay products and store them in a SQLite database. Built for raspberry pi to run the python script and host locally the full stack flask app which visualizes the data. Its mockup is live online.',
    skills: ['Python', 'Flask', 'BeautifulSoup4'],
  },
  {
    id: '7',
    title: 'House Price Tracker',
    image: zillowImage,
    codeUrl: 'https://github.com/Kota-N/zillow-price-tracker-flask.git',
    visitUrl: 'https://kota-n.github.io/zillow-price-tracker-mockup/',
    desc:
      'A program that tracks prices of houses and store them in a SQLite database. Built for raspberry pi to run the python script and host locally the full stack flask app which visualizes the data. Its mockup is live online.',
    skills: ['Python', 'Flask', 'BeautifulSoup4'],
  },
  {
    id: '9',
    title: 'Toy Piano',
    image: toypianoImage,
    codeUrl: 'https://github.com/Kota-N/ToyPiano.git',
    visitUrl: 'https://kota-n.github.io/ToyPiano/',
    desc:
      'A playable web app. Keyboard shortcuts make it possible to play scales and chords on the keyboard. An auto-play mode will automatically play a song for you.',
    skills: ['HTML', 'CSS', 'JavaScript'],
  },
  {
    id: '10',
    title: 'Form Validator',
    image: validatorImage,
    codeUrl: 'https://github.com/Kota-N/FormValidator.git',
    visitUrl: 'https://kota-n.github.io/FormValidator/',
    desc:
      'A client-side form validator. Checks valid email address and lengths of name and password.',
    skills: ['HTML', 'CSS', 'JavaScript'],
  },
];

export default projectsData;
